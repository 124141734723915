<template>
  <div class="modal-header-component">
    <div class="d-flex" v-if="config.type == 'back'">
      <button class="back-button" @click="goBack()">
        <span>&lt;</span>
        BACK
      </button>
      <div class="vehicle-picker-container" v-if="config.hasVehiclePicker">
        <VehiclePickerMenu />
      </div>
    </div>
    <template v-else>
      <div class="main">
        <div class="d-flex align-items-center">
          <AdminModalMenu
            @close="$emit('close')"
            v-if="config.type == 'admin'"
          />
          <VehicleModalMenu
            @close="$emit('close')"
            v-if="['geo-zone', 'reminder', 'vehicle'].includes(config.type)"
          />
          <span class="title" v-if="config.title">{{ config.title }}</span>
        </div>
        <span class="subtitle" v-if="config.subTitle">{{
          config.subTitle
        }}</span>
      </div>
      <div
        class="vehicle-picker-container"
        v-if="config.type == 'vehicle' && config.hasVehiclePicker !== false"
      >
        <VehiclePickerMenu />
      </div>
    </template>
    <Tooltip
      class="ns-tooltip"
      @click.native="showTooltip"
      v-if="config.tooltipScreenName"
    />
    <CloseButton @click="$emit('close')" />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.modal-header-component {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  .back-button {
    display: flex;
    align-items: center;
    background: none;
    color: $brand-color-2;
    padding: 0;
    margin: -2px 0 0 -20px;
    border: none;

    > span {
      font-weight: 600;
      color: $brand-color-1;
      margin-right: 2px;
    }

    &:focus {
      outline: none;
    }
  }

  .main {
    display: flex;
    align-items: center;

    .title,
    .subtitle {
      font-size: 1.125rem;
      text-transform: uppercase;
    }

    .title {
      font-weight: bold;
      color: $brand-color-1;
    }

    .subtitle {
      //font-family: $font-mont-book;
      font-family: $font-mont;
      color: $brand-color-2;
      padding-left: 10px;
    }
  }

  .vehicle-picker-container {
    padding-left: 20px;
  }

  .ns-tooltip {
    position: absolute;
    top: 9px;
    right: 55px;
  }
}

@media (max-width: 767px) {
  .modal-header-component {
    .main {
      display: block;

      .subtitle {
        display: block;
        padding-left: 25px;
      }
    }
  }
}
</style>

<script>
import AdminModalMenu from "@/components/screens/AdminModalMenu";
import VehicleModalMenu from "@/components/screens/VehicleModalMenu";
import VehiclePickerMenu from "@/components/screens/VehiclePickerMenu";
import CloseButton from "@/components/CloseButton";
import Tooltip from "@/components/Tooltip";
import { modalService, tooltipService } from "@/services";

export default {
  name: "ModalHeader",
  components: {
    AdminModalMenu,
    VehicleModalMenu,
    VehiclePickerMenu,
    CloseButton,
    Tooltip,
  },
  props: {
    config: {
      type: Object,
    },
  },
  methods: {
    showTooltip() {
      tooltipService.showTooltip(this.config.tooltipScreenName);

      let eventName = "";

      switch (this.config.tooltipScreenName) {
        case "alerts":
          eventName = "tooltip_vehicle_alerts";
          break;
        case "behaviour":
          eventName = "tooltip_vehicle_behaviour";
          break;
        case "early-warning":
          eventName = "tooltip_vehicle_early-warning";
          break;
        case "geo-zones":
          eventName = "tooltip_vehicle_geo-zones";
          break;
        case "logbooks":
          eventName = "tooltip_vehicle_logbooks";
          break;
        case "manage-vehicle":
          eventName = "tooltip_vehicle_manage-vehicle";
          break;
        case "reminders":
          eventName = "tooltip_vehicle_reminders";
          break;
        case "trips":
          eventName = "tooltip_vehicle_trips";
          break;
      }

      if (eventName) {
        try {
          this.$gtag.event(eventName);
          this.$gtag.event("tooltip");
        } catch (err) {}
      }
    },
    goBack() {
      if (this.$isMobile) {
        // Handle special case for alert screens
        if (
          ["manage-alerts", "new-alert-entry"].includes(this.config.screenName)
        ) {
          modalService.showModal(this, this.config.screenName);
        } else {
          this.$emit("close");
        }
      } else {
        if (this.config.screenType && this.config.screenType === "drawer") {
          modalService.showLeftDrawer(this, this.config.screenName);
        } else {
          modalService.showModal(this, this.config.screenName);
        }
      }
    },
  },
  watch: {
    config(value) {
      if (value.navigateBack) {
        this.goBack();
      }
    },
  },
};
</script>
