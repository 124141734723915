<template>
  <div class="menu">
    <ul class="menu__navbar">
      <li class="menu__item">
        <a class="menu__link">
          <button class="menu-button" v-if="show" key="on" @click="show = false">
            <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="icon">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
          </button>
          <button class="menu-button" v-else key="off" @click="show = true">
            <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" class="icon">
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        </a>
        <transition name="dropdown">
          <div class="dropdown__menu" :class="{ active: show }" v-if="show">
            <ul class="dropdown__menu-nav">
              <li class="dropdown__menu-item">
                <a
                  class="dropdown__menu-link"
                  title="Personal Details"
                  @click="showModal('personal-details')"
                  v-if="'personal-details' != navigation.activeScreenName"
                >
                  <div class="dropdown__menu-text">Personal Details</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                  class="dropdown__menu-link"
                  title="Financial Details"
                  @click="showModal('financial-details')"
                  v-if="'financial-details' != navigation.activeScreenName"
                >
                  <div class="dropdown__menu-text">Financial Details</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a class="dropdown__menu-link" title="Documents" @click="showModal('documents')" v-if="'documents' != navigation.activeScreenName">
                  <div class="dropdown__menu-text">Documents</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a class="dropdown__menu-link" title="Get In Touch" @click="showModal('get-in-touch')" v-if="'get-in-touch' != navigation.activeScreenName">
                  <div class="dropdown__menu-text">Get In Touch</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a class="dropdown__menu-link" title="Feedback" @click="showModal('feedback')" v-if="'feedback' != navigation.activeScreenName">
                  <div class="dropdown__menu-text">Feedback</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a class="dropdown__menu-link" title="FAQs" @click="showModal('faqs')" v-if="'faqs' != navigation.activeScreenName">
                  <div class="dropdown__menu-text">FAQs</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                  class="dropdown__menu-link"
                  title="Terms & Conditions"
                  @click="showModal('terms-and-conditions')"
                  v-if="'terms-and-conditions' != navigation.activeScreenName"
                >
                  <div class="dropdown__menu-text">Terms & Conditions</div>
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.menu {
  position: relative;

  &__navbar {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-button {
    background-color: transparent;
    cursor: pointer;
    padding: 0 5px 0 0;
    border: none;

    &:focus {
      outline: 0;
    }

    .icon {
      fill: $brand-color-1;
    }
  }
}

.dropdown__menu {
  background: #fff;
  position: fixed;
  top: auto;
  left: auto;
  z-index: 1;
  width: 250px;
  overflow-y: auto;
  padding: 15px;
  box-shadow: $drop-shadow;

  &-nav {
    list-style: none;
    padding: 0;
    border-left: 1px solid $brand-color-2;
  }

  &-item {
    &:first-of-type {
      a {
        padding-top: 0;
      }
    }

    &:last-of-type {
      a {
        padding-bottom: 0;
      }
    }
  }

  &-link {
    display: block;
    font-size: 0.75rem;
    color: $brand-color-1;
    text-decoration: none;
    text-transform: uppercase;
    padding: 6px 10px;

    &:hover {
      color: $brand-color-2;
    }
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>

<script>
import { mapState } from "vuex";
import { modalService } from "@/services";

export default {
  name: "AdminModalMenu",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState(["navigation"]),
  },
  methods: {
    showModal(screenName) {
      modalService.showModal(this, screenName);
    },
  },
};
</script>