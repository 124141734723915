<template>
  <div class="menu">
    <ul class="menu__navbar">
      <li class="menu__item">
        <a class="menu__link">
          <button
              class="menu-button"
              v-if="show"
              key="on"
              @click="show = false"
          >
            <svg
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="icon"
            >
              <path
                  fill-rule="evenodd"
                  d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
              />
            </svg>
          </button>
          <button class="menu-button" v-else key="off" @click="show = true">
            <svg
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="icon"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        </a>
        <transition name="dropdown">
          <div class="dropdown__menu" :class="{ active: show }" v-if="show">
            <ul class="dropdown__menu-nav">
              <li class="dropdown__menu-item">
                <a
                    class="dropdown__menu-link"
                    title="Alerts"
                    @click="showLeftDrawer('alerts')"
                    v-if="
                    hasAlerts &&
                      !isInRecovery &&
                      'alerts' != navigation.activeScreenName
                  "
                >
                  <div class="dropdown__menu-text">Alerts</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                  class="dropdown__menu-link"
                  title="Behaviour"
                  @click="showModal('behaviour')"
                  v-if="
                    hasBehaviour &&
                      !isInRecovery &&
                      'behaviour' != navigation.activeScreenName
                  "
                >
                  <div class="dropdown__menu-text">Behaviour</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                    class="dropdown__menu-link"
                    title="Certificates"
                    @click="showModal('certificates')"
                    v-if="'certificates' != navigation.activeScreenName"
                >
                  <div class="dropdown__menu-text">Certificates</div>
                </a>
              </li>
              <li
                  class="dropdown__menu-item"
                  v-if="getActiveVehicleIsEarlyWarning()"
              >
                <a
                    class="dropdown__menu-link"
                    title="Early Warning"
                    @click="showModal('early-warning')"
                    v-if="
                    !isInRecovery &&
                      'early-warning' != navigation.activeScreenName
                  "
                >
                  <div class="dropdown__menu-text">Early Warning</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                  class="dropdown__menu-link"
                  title="Geo Zones"
                  @click="showLeftDrawer('geo-zones')"
                  v-if="
                    hasMap &&
                      !isInRecovery &&
                      'geo-zones' != navigation.activeScreenName
                  "
                >
                  <div class="dropdown__menu-text">Geo Zones</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                    class="dropdown__menu-link"
                    title="Health Status"
                    @click="showModal('health-status')"
                    v-if="
                    !isInRecovery &&
                      'health-status' != navigation.activeScreenName
                  "
                >
                  <div class="dropdown__menu-text">Health Status</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                    class="dropdown__menu-link"
                    title="Logbooks"
                    @click="showModal('logbooks')"
                    v-if="
                    hasTrips &&
                      !isInRecovery &&
                      'logbooks' != navigation.activeScreenName
                  "
                >
                  <div class="dropdown__menu-text">Logbooks</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                  class="dropdown__menu-link"
                  title="Manage Vehicle"
                  @click="showModal('manage-vehicle')"
                  v-if="'manage-vehicle' != navigation.activeScreenName"
                >
                  <div class="dropdown__menu-text">Manage Vehicle</div>
                </a>
              </li>
              <li class="dropdown__menu-item">
                <a
                    class="dropdown__menu-link"
                    title="Reminders"
                    @click="showModal('reminders')"
                    v-if="'reminders' != navigation.activeScreenName"
                >
                  <div class="dropdown__menu-text">Reminders</div>
                </a>
              </li>
              <!-- <li class="dropdown__menu-item">
                <a
                  class="dropdown__menu-link"
                  title="Share Vehicle"
                  @click="showModal('share-vehicles')"
                  v-if="'share-vehicle' != navigation.activeScreenName"
                >
                  <div class="dropdown__menu-text">Share Vehicle</div>
                </a>
              </li> -->
              <li class="dropdown__menu-item">
                <a
                    class="dropdown__menu-link"
                    title="Trips"
                    @click="showLeftDrawer('trips')"
                    v-if="
                    hasTrips &&
                      !isInRecovery &&
                      'trips' != navigation.activeScreenName
                  "
                >
                  <div class="dropdown__menu-text">Trips</div>
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.menu {
  position: relative;

  &__navbar {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-button {
    background-color: transparent;
    cursor: pointer;
    padding: 0 5px 0 0;
    border: none;

    &:focus {
      outline: 0;
    }

    .icon {
      fill: $brand-color-1;
    }
  }
}

.dropdown__menu {
  background: #fff;
  position: fixed;
  top: auto;
  left: auto;
  z-index: 2;
  width: 250px;
  overflow-y: auto;
  padding: 15px;
  box-shadow: $drop-shadow;

  &-nav {
    list-style: none;
    padding: 0;
    border-left: 1px solid $brand-color-2;
  }

  &-item {
    &:first-of-type {
      a {
        padding-top: 0;
      }
    }

    &:last-of-type {
      a {
        padding-bottom: 0;
      }
    }
  }

  &-link {
    display: block;
    font-size: 0.75rem;
    color: $brand-color-1;
    text-decoration: none;
    text-transform: uppercase;
    padding: 6px 10px;

    &:hover {
      color: $brand-color-2;
    }
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { assetService, modalService } from "@/services";

export default {
  name: "VehicleModalMenu",
  data() {
    return {
      asset: null,
      show: false,
    };
  },
  computed: {
    ...mapState(["navigation"]),
    ...mapGetters("asset", [
      "getAsset",
      "getActiveSafeAndSoundVehicleId",
      "getActiveVehicleIsEarlyWarning",
    ]),
    activeVehicleId() {
      return this.getActiveSafeAndSoundVehicleId();
    },
    hasAlerts() {
      return (
          this.asset && assetService.hasAlertsFeature(this.asset.ProductName)
      );
    },
    hasBehaviour() {
      return (
          this.asset && assetService.hasBehaviourFeature(this.asset.ProductName)
      );
    },
    hasMap() {
      return this.asset && assetService.hasMapFeature(this.asset.ProductName);
    },
    hasShareVehicle() {
      return false;
      // return this.asset && assetService.hasShareVehicleFeature(this.asset.ProductName);
    },
    hasTrips() {
      return this.asset && assetService.hasTripsFeature(this.asset.ProductName);
    },
    isInRecovery() {
      return this.asset && this.asset.IsInRecovery;
    },
  },
  created() {
    this.asset = this.getAsset(this.activeVehicleId);
  },
  methods: {
    showModal(screenName) {
      modalService.showModal(this, screenName);
    },
    showLeftDrawer(screenName) {
      modalService.showLeftDrawer(this, screenName);
    },
  },
  watch: {
    activeVehicleId(value) {
      this.asset = this.getAsset(value);
    },
  },
};
</script>
