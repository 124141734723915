var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('ul',{staticClass:"menu__navbar"},[_c('li',{staticClass:"menu__item"},[_c('a',{staticClass:"menu__link"},[(_vm.show)?_c('button',{key:"on",staticClass:"menu-button",on:{"click":function($event){_vm.show = false}}},[_c('svg',{staticClass:"icon",attrs:{"width":"20","height":"20","viewBox":"0 0 16 16","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"}})])]):_c('button',{key:"off",staticClass:"menu-button",on:{"click":function($event){_vm.show = true}}},[_c('svg',{staticClass:"icon",attrs:{"width":"20","height":"20","viewBox":"0 0 16 16","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"}})])])]),_c('transition',{attrs:{"name":"dropdown"}},[(_vm.show)?_c('div',{staticClass:"dropdown__menu",class:{ active: _vm.show }},[_c('ul',{staticClass:"dropdown__menu-nav"},[_c('li',{staticClass:"dropdown__menu-item"},[(
                  _vm.hasAlerts &&
                    !_vm.isInRecovery &&
                    'alerts' != _vm.navigation.activeScreenName
                )?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Alerts"},on:{"click":function($event){return _vm.showLeftDrawer('alerts')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Alerts")])]):_vm._e()]),_c('li',{staticClass:"dropdown__menu-item"},[(
                  _vm.hasBehaviour &&
                    !_vm.isInRecovery &&
                    'behaviour' != _vm.navigation.activeScreenName
                )?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Behaviour"},on:{"click":function($event){return _vm.showModal('behaviour')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Behaviour")])]):_vm._e()]),_c('li',{staticClass:"dropdown__menu-item"},[('certificates' != _vm.navigation.activeScreenName)?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Certificates"},on:{"click":function($event){return _vm.showModal('certificates')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Certificates")])]):_vm._e()]),(_vm.getActiveVehicleIsEarlyWarning())?_c('li',{staticClass:"dropdown__menu-item"},[(
                  !_vm.isInRecovery &&
                    'early-warning' != _vm.navigation.activeScreenName
                )?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Early Warning"},on:{"click":function($event){return _vm.showModal('early-warning')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Early Warning")])]):_vm._e()]):_vm._e(),_c('li',{staticClass:"dropdown__menu-item"},[(
                  _vm.hasMap &&
                    !_vm.isInRecovery &&
                    'geo-zones' != _vm.navigation.activeScreenName
                )?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Geo Zones"},on:{"click":function($event){return _vm.showLeftDrawer('geo-zones')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Geo Zones")])]):_vm._e()]),_c('li',{staticClass:"dropdown__menu-item"},[(
                  !_vm.isInRecovery &&
                    'health-status' != _vm.navigation.activeScreenName
                )?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Health Status"},on:{"click":function($event){return _vm.showModal('health-status')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Health Status")])]):_vm._e()]),_c('li',{staticClass:"dropdown__menu-item"},[(
                  _vm.hasTrips &&
                    !_vm.isInRecovery &&
                    'logbooks' != _vm.navigation.activeScreenName
                )?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Logbooks"},on:{"click":function($event){return _vm.showModal('logbooks')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Logbooks")])]):_vm._e()]),_c('li',{staticClass:"dropdown__menu-item"},[('manage-vehicle' != _vm.navigation.activeScreenName)?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Manage Vehicle"},on:{"click":function($event){return _vm.showModal('manage-vehicle')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Manage Vehicle")])]):_vm._e()]),_c('li',{staticClass:"dropdown__menu-item"},[('reminders' != _vm.navigation.activeScreenName)?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Reminders"},on:{"click":function($event){return _vm.showModal('reminders')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Reminders")])]):_vm._e()]),_c('li',{staticClass:"dropdown__menu-item"},[(
                  _vm.hasTrips &&
                    !_vm.isInRecovery &&
                    'trips' != _vm.navigation.activeScreenName
                )?_c('a',{staticClass:"dropdown__menu-link",attrs:{"title":"Trips"},on:{"click":function($event){return _vm.showLeftDrawer('trips')}}},[_c('div',{staticClass:"dropdown__menu-text"},[_vm._v("Trips")])]):_vm._e()])])]):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }